import type { LanguageOption } from './consts';

interface Response {
  stderr: string;
  stdout: string;
  exit_code: number;
}

interface PostSnippetData {
  language: LanguageOption;
  code: string;
}

export const postSnippet = async (
  data: PostSnippetData,
  snippetsBaseUrl?: string
): Promise<Response> => {
  const snippetsEndpoint = `https://${snippetsBaseUrl}/snippets`;

  const response = await fetch(snippetsEndpoint, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response.json();
};
